<template>
  <div class="p-grid p-justify-center p-formgrid">
    <div class="p-col-12 p-md-4">
      <div class="card p-fluid">
        <form @submit.prevent="submitForm">
          <div class="p-field">
            <label for="login">Введите ваш телефон</label>
            <InputMask
              mask="+7-999-999-99-99"
              id="login"
              v-model.trim="login.val"
              :required="true"
              :class="!login.isValid ? 'p-invalid' : ''"
              @blur="clearValidity('login')"
              :autoClear="false"
              @keyup.enter="submitForm"
              :disabled="isLoading || enterCode || doneChange"
            />
          </div>
          <div class="p-field" v-if="enterCode">
            <label for="code">Введите код из WhatsApp</label>
            <InputText
              id="code"
              @blur="clearValidity('code')"
              :class="!code.isValid ? 'p-invalid' : ''"
              v-model.trim="code.val"
              :required="true"
              @keyup.enter="submitForm"
              :disabled="isLoading || doneChange"
            />
          </div>
          <Button
            v-if="!doneChange"
            label="Отправить код"
            @click="submitForm"
            :disabled="isLoading"
          ></Button>
          <div class="p-mt-4 p-text-center" v-if="doneChange">
            <router-link
              :to="{
                name: 'Login',
              }"
              >Войти с новым паролем
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "RestorePassword",
  data() {
    return {
      login: {
        val: "",
        isValid: true,
      },
      code: {
        val: "",
        isValid: true,
      },
      formIsValid: true,
      error: null,
      isLoading: false,
      enterCode: false,
      doneChange: false,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.login.isValid = true;
      if (!this.login.val) {
        this.login.isValid = false;
        this.formIsValid = false;
      }
      if (this.enterCode) {
        if (!this.code.val) {
          this.code.isValid = false;
          this.formIsValid = false;
        }
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        phone: this.login.val,
      };

      try {
        if (this.enterCode) {
          actionPayload.code = this.code.val;
          const response = await fetch(
            `${environment.apiUrl}/password/confirm_code`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(actionPayload),
            }
          );

          if (response.status !== 200) {
            // const responseData = await response.json();
            // console.log('r2', responseData);
            throw new Error("Ошибка подтверждения кода!");
          }

          this.$toast.add({
            severity: "success",
            summary: "",
            detail: `Пароль обновлён!`,
            life: 6000,
          });

          this.doneChange = true;
        } else {
          const response = await fetch(
            `${environment.apiUrl}/password/change_password`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(actionPayload),
            }
          );

          if (response.status !== 200) {
            const responseData = await response.json();
            throw new Error(
              responseData.message || "Ошибка запроса на смену пароля!"
            );
          }

          this.enterCode = true;
        }
      } catch (err) {
        this.error = err.message || "Не получилось сменить пароль";
        // console.error("error!", this.error);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при смене пароля!",
          detail: this.error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
